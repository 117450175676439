
import React, { useState } from 'react'
import { useNavigate  } from "react-router-dom";
import "./Home.css"
import FlowchartImage from "../../images/flowchart-image.svg"
import { Button, MenuItem, Select, TextField } from '@material-ui/core'
import { Tooltip } from '@material-ui/core';
import info from "../../images/info-icon.svg"

const Home = ({name,setName,fetchQuestion,deviceInfo}) => {
  
  const[designation,setDesignation] = useState("");    
  const[department,setDepartment] = useState(""); 
  const[Installid,setInstallid] = useState("");   
  const[errorOnSubmit,setErrorOnSubmit] = useState(false)
  const [errorMessage,setErrorMessage] = useState("Please fill all the required fields")
  const navigate = useNavigate ();

  const Categories = [
    {category: "ONE",value: "one"},
    {category: "TIM",value: "tim"},
    {category: "PAMC",value: "pamc"},
    {category: "FLO",value: "flo"},
  ];

  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z ]+$/;
  const NUMERIC_REGEX = /^[0-9]+$/; 
 

    const one = [
        {category: "Gen 2 with Proxy",value: "Gen 2 with Proxy"},
        {category: "Gen 2 Without Proxy with Temp and Humidity",value: "Gen 2 Without Proxy with Temp and Humidity"},
        {category: "Gen 3 with Proxy",value: "Gen 3 with Proxy"},
        {category: "Gen 3 without Proxy",value: "Gen 3 without Proxy"},
    ];

    const tim = [];
    const pamc = [];
    const flo = [];

  const checkInstallId = () => {
    if(Installid.match(/^\d{10}$/) ){
        return false
    }else{
        setErrorMessage("Please provide the valid Install ID.")
        return true
    }
  }  

  const checkUserInfo = ()=>{
    if(!department || !designation || !name){
        setErrorMessage("Please fill all the required fields.")
        return true
    }else{
        return false
    }
  }
  
  const handleSubmit = () => {
    if(checkUserInfo() || checkInstallId()){
        setErrorOnSubmit(true)
    }
    else{
        setErrorOnSubmit(false)
        fetchQuestion(designation.trim(),department.trim(),Installid)
        // deviceInfo(Installid,dD)
        navigate("/question")
    }
    
  }
  
  return (
    <div className='content'>
        <div className='form-container'>
            
            

            <div className='form-select'>
            <span style={{fontSize:"30px",fontWeight:"700", margin:"20px 0",color:"#542915"}}> User Info</span>
                <div className='input-form-select'>
                    <TextField 
                        style={{flex:"1"}} 
                        label="Enter Your Name" 
                        variant='outlined'
                        onChange={(event) => {
                            const value = event.target.value;
                            if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                                return;
                            }
                            setName(value);
                        }}
                        value={name}
                        inputProps={{ maxLength: 25 }} 
                     />
                </div>

                <div className='input-form-select'>  
                    <TextField 
                        style={{flex:"1"}} 
                        label="Designation" 
                        variant='outlined'
                        onChange={(event) => {
                            const value = event.target.value;
                            if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                                return;
                            }
                            setDesignation(value);
                        }}
                        value={designation}
                        inputProps={{ maxLength: 25 }} 
                    />
                    
                </div>

                <div className='input-form-select'>     
                    <TextField 
                        style={{flex:"1"}} 
                        label="Department" 
                        variant='outlined'
                        onChange={(event) => {
                            const value = event.target.value;
                            if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                                return;
                            }
                            setDepartment(value);
                        }}
                        value={department}
                        inputProps={{ maxLength: 25 }} 

                    />

                </div>
                {/* {errorOnSubmit && <h1 className='error-message'>{errorMessage}</h1>}  */}
                </div>
                <div className='form-select'>
                <span style={{fontSize:"30px",fontWeight:"700", margin:"20px 0",color:"#542915"}}> Device Info</span>


                <div className='input-form-select'>
                    <TextField 
                        style={{flex:"1"}} 
                        label="Install ID" 
                        variant='outlined'
                        onChange={(event) => {
                            const value = event.target.value;
                            if ((value !== "" && !NUMERIC_REGEX.test(value)) || value.length > 10) {
                                return;
                            }
                            setInstallid(value);
                        }}
                        value={Installid}
                    />
                    <Tooltip title="10 digit Numeric value and cannot contain special characters">
                        <span style={{marginLeft:"5px"}}><img src={info} style={{verticalAlign:"sub"}}></img></span>
                    </Tooltip>
                </div>
               
                
            {errorOnSubmit && <h1 className='error-message'>{errorMessage}</h1>} 
                <Button style={{marginTop:"20px"}} variant='contained' color="primary" size='large' onClick={handleSubmit}>
                    Submit
                </Button>
            </div>
        </div>

        <img src={FlowchartImage} className="banner"></img>
    </div>
  )
}

export default Home