import React from 'react';
import freespaceIcon from "../../images/Freespace.svg"

const footerStyle ={
  Weight: "700, Bold",
  style: "normal",
  size: "13px",
  align: "Center",
  margin:"20px 0 0 0",
  opacity:"1",
  display:"flex",
  justifyContent:"center",
  flexDirection: "column",
  alignItems: "center",
  // position:"sticky",
  // width:"100%",
  // bottom:"0",
  // background:"#f4f4f4"
}
class Footer extends React.Component {

  render() {
    return (<footer className="main-footer" style={footerStyle}>
      <span>Copyright &copy; {new Date().getFullYear()} <a style={{ 'color': '#202153' }} href="http://www.afreespace.com/" target="_blank" rel="noreferrer"><img src={freespaceIcon} style={{verticalAlign: "middle", height: "13px"}} alt="logo"></img></a></span>
      <span>All rights reserved.</span>
    </footer>);
  }
}

export default Footer;