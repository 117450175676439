import "../../css/QuestionBlock.css"
import {useState} from "react"
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import downloadIcon from "../../images/download.svg"
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment-timezone";
import { TextField } from '@material-ui/core'

function QuestionBlock({questions,userDetail,deviceInformation,currTime}) {
    const [selected, setselected] = useState();
    const [currentQuestion, setcurrentQuestion] = useState(1)
    const [userInfo,setUserInfo] = useState(
        [
          { key: "Name", value: userDetail.Name },
          { key: "Department", value: userDetail.Department },
          { key: "Designation", value: userDetail.Designation },
          { key: "Date", value: currTime.format("YYYY-MM-DD HH:mm") },
        ]
      )

    const [deviceInfo,setdeviceInfo] = useState(
        [
            { key: "Device Type", value: deviceInformation.DeviceType },
            { key: "Sub-Category", value: deviceInformation.DeviceSubType },
            { key: "Client", value: deviceInformation.Account },
            { key: "Mac Id", value: deviceInformation.MacId },
            { key: "RSSI", value: deviceInformation.Rssi},
            { key: "InstallId", value: deviceInformation.InstallId },
            { key: "Bless Id", value: deviceInformation.blessId },
            { key: "Space Id", value: deviceInformation.SpaceId },
            { key: "Location", value: deviceInformation.Location },
            { key: "Floor Id", value: deviceInformation.FloorId },
            { key: "Last Connect", value: deviceInformation.LastConnect },
            { key: "Last Updated", value: moment.unix(deviceInformation.LastUpdated).format('DD/MM/YYYY HH:mm:ss') },
            { key: "Build Version", value: deviceInformation.BuildVersion },
        ]
    )
    
    const [stepsPerformed,setStepsPerformed] = useState([])
    const [conclusion,setConclusion] = useState("")   
    const [valueByUser,setValueByUser] = useState("")
    // const navigate = useNavigate ();
    
    const handleInputValue = (i,evt) => {

        setValueByUser({
            ...valueByUser,
            [i]: evt.target.value
          });
    }

    const handleConclusion = () => {
        let str= ""
        let con= questions[currentQuestion].conclusion ? questions[currentQuestion].conclusion : ""
                    for (const [key,value] of Object.entries(valueByUser)){
                        str = str + ` ${key} : ${value}`
                    } 
                    setConclusion(con + str)
    }
    const handleClick = (e) => {
        if(e === "Yes" || e === "Next"){
            if(questions[currentQuestion].conclusion){
                if(valueByUser){
                    handleConclusion()  
                }
                else{
                    setConclusion(questions[currentQuestion].conclusion)
                }
            }
            else if(valueByUser){
                handleConclusion()
            } 
            
            setcurrentQuestion(`${currentQuestion}` + `1`)

            let oldState = [...stepsPerformed]
            oldState.push({key:questions[currentQuestion].question ? questions[currentQuestion].question: questions[currentQuestion].instruction ,
                value: e})
            setStepsPerformed(oldState)
        } 
        else{
            setcurrentQuestion(`${currentQuestion}` + `0`)

            let oldState = [...stepsPerformed]
            oldState.push({key:questions[currentQuestion].question ? questions[currentQuestion].question: questions[currentQuestion].instruction ,
                value: e})
            setStepsPerformed(oldState)
        }
       
    }

    
    const exportPDF = () => {
        const unit = "mm";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 20;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(20);
        const heading = "Freespace Support Level 1 Report For " + deviceInformation.DeviceType;
        const title = "User Information";
        const title1 = "Device Information";
        const title2 = "Troubleshooting Steps";
        const title3 = "Conclusion"
        const headers = [["",""]];  //should be equal to number of columns available in data
    
        const data = userInfo.map(elt=> [elt.key, elt.value]);
        const data1 = deviceInfo.map(elt=> [elt.key, elt.value]);
        const data2 = stepsPerformed.map(elt=> [elt.key, elt.value]);

        let content = {
          startY: 50,
          body: data,
          theme:"grid",
          styles: {
            overflow: 'ellipsize',
            fontSize: 12,
            valign: 'middle'
        },
          headStyles :{fillColor : [215, 57, 37]},
          bodyStyles :{fillColor : [219, 220, 220]},
        //   alternateRowStyles: {fillColor : [236, 240, 245]},
          tableLineWidth: 0.1,
          fillColor:"50"
        };

        // var fontSize = doc.internal.getFontSize();             //For getting heading on center
        // var pageWidth = doc.internal.pageSize.width;
        // let txtWidth = doc.getStringUnitWidth('Freespace')*fontSize/doc.internal.scaleFactor;
        // const x = ( pageWidth - txtWidth ) / 2;

        doc.setTextColor(30, 37, 62);
        doc.setFontSize(15);
        doc.text(heading , 5 ,10);
        doc.setTextColor(0, 0, 0);
        doc.text(title, marginLeft, 40);   //table 1
        doc.autoTable(content);
        
        let valueY = doc.lastAutoTable.finalY;   //table 2
        doc.text(title1, marginLeft, valueY + 40);
        doc.autoTable({...content, 
            body:data1,
            startY: valueY + 50
        });
        
        doc.addPage();
        valueY = doc.lastAutoTable.finalY;    //table 3
        doc.text(title2, marginLeft, 40);
        doc.autoTable({...content, 
            head: [["Troubleshooting Steps","Outcome"]],
            body:data2,
        });

        valueY = doc.lastAutoTable.finalY;    //conclusion
        doc.text(title3, marginLeft, valueY + 40);
        doc.setFontSize(15);
        doc.setTextColor(56, 214, 139);

        var splitTitle = doc.splitTextToSize(conclusion, 180);  //For long string text go outside page
        doc.text(splitTitle,marginLeft,valueY + 50)
        

        
        doc.save(`support_tool_report_${currTime.format("ddd_MMM_DD_YYYY")}.pdf`);      //table download
      }
    

    return (
        questions[currentQuestion] ? 
        <div className="question">
            <div className="single-question">
                <h2 style={{color:"#38d68b",marginBottom:"20px"}}>
                {questions[currentQuestion].instruction ? questions[currentQuestion].instruction : ""}
                    
                </h2>
                <span>
                    {questions[currentQuestion].image ? <img src={questions[currentQuestion].image} alt="calender"></img>  :"" }
                    </span>
                <h2>
                    {questions[currentQuestion].question}
                </h2>
                {questions[currentQuestion].input ? 
                    questions[currentQuestion].input.map((i) => (
                        <TextField 
                            key={i}
                            style={{flex:"1",marginBottom:"25px"}} 
                            label={i}
                            variant='outlined'
                            onChange={(evt) => handleInputValue(i,evt)}
                            value={valueByUser[i]}
                        />
                    )) : null  
                }
                <div className="options">
                    {true &&
                        questions[currentQuestion].options.map((i) => (
                        <button
                            className={"single-option"}
                            key={i}
                            disabled={selected}
                            onClick={() => handleClick(i)}
                        >
                            {i}
                        </button>
                        ))}
                </div>
                <div>
                <Link to="/">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ width: 185 }}
                    
                    >
                        Back
                    </Button>
                </Link>
                
                
                </div>
            </div>
        </div>:
        <div className="download-button">
             <Button 
            variant="contained"
            color="primary"
            size="large"
            // style={{ width: 185 }}
            onClick={() => exportPDF()}
        >
            <img src={downloadIcon} style={{padding:"5px"}} />Download PDF
        </Button>
        </div>

    );
}

export default QuestionBlock;