import calender from "../../images/calender.png"
const oneData = {

    1:{instruction:"Check the admin portal for health parameters",question:"Whether 'Updated' column shows todays date?", options:["Yes","No"]},
    
    11:{
        instruction:"Check Event Log page. Search for below icon on Admin page.",
         options:["Next"],
         image:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAABJCAYAAABvujtvAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAMkSURBVHhe7ZzfS1NhGMe/yzk1rWn+wMzADBL7Id0EUXpVNwZ1UdfdJ3Qd1FVBN/UPREEUSBdS0EV0ESFdmJQW2Q8EqdSpTVGnzvljm9u0ne0NPfP47HjUY7LvB8b2vIeXZ/vwvue8z3vGcYTD4SUQQ3apd2LAhkeOZ3Iezz4PonNgCrPhqGqNW3c4UFrgQuPRclyuq0B+jlMd2RzsyGtZjtbpxVcvHn/wIBJbTDauQWlBDu5cqEVN2R7VYh0781qeVi+/DeNRe3/aL6gxPhvGrVfd6PXNqRbr2JnXkhzvdAgtXUNYXDI/6PzBCJo/DSK6aH0W253XkpzOgUn4ZhdUZJ4fwwEMTc2raP3YndeSnJ7RGfVpmfM1ZXh97QxarzckXs1XT+FQcb46miQQimAkEFLR+rE7ryU5MYMhWl9djFxnloqACncuqvbtVlESbToY9TWL3Xktn5AzAcoRoBwBw0Xg6EwYTzo8aPs9gVA0plplbjfWouFwiYqS3H3Tg3e/xlW0NWw0b0m+C1dOVuLSiXLduUtj1cjpHgmgqaULb3vGTIvZyfjmFvCwvQ8344vFlWWIhk7OWHxFeb/1J6bjl75M47t3Gg/e9+kWizo57X0T+OMPqijz+OiZ0i0WdXK0KZXJ+IMLusHBq5UA5QhQjsCmyXne5UX/xFzi0qi92np9+DLkV0e3jq3Mq1sE2rFo+99ZuajktBKgHAHKEaAcAcoRSHu1MtoSWAttl/9px0DiRtq9i8dRW27+ftF29E33ezlyBChHgHIEKEeAcgQoR4ByBChHgHIEKEcgbfngzsuGK8ucw2Aklrgxpv0vz53rhNNkP43t6DsTiq66cbmyfOBOYArrksORo2BVzqrcNJQjQDkClCNAOQKUI0A5ApQjQDkClCNAOQIsPLllYR5uWSi4ZSHALYsNQDkClCNAOQKUI0A5ApQjQDkClCNAOQIsPFNYs3w4tn+v+pSZFOa5UFmYp6IUOWeri3UHM43TVUU4WLT8kBCdnLKCHNw4dyRe9merlsyh7oAbTfXVcO5yqJaUc84/rDyuYaciPa7BUA7RAP4CwBBdvNHMJeUAAAAASUVORK5CYII="
        },
    10:{instruction:"Check the admin portal for health parameters",question:"Whether 'Presence' column shows todays date?", options:["Yes","No"]},
    
    101:{instruction:"Network was absent during som, revisit the next day", input:["TOt","TOy","RSSI"],options:["Next"],conclusion:"Network was absent during som, revisit the next day."},

    111:{instruction:"Check event list page and observer 'State' Column value.\n Value 1 represents presence \nValue 0 represents absence",question:"Whether any presence activity today?", options:["Yes","No"]},

    1111:{instruction:"Device is healthy",conclusion:"Device is healthy", options:["Next"]},
    1110:{instruction:"SOM has happened and status was not seen",conclusion:"SOM has happened and status was not seen hence potential PIR issue", options:["Next"]},   

    
    100:{instruction:"Check the admin portal for health parameters",question:"Whether 'Last connected' column shows todays date?", options:["Yes","No"]},
    
    1001:{instruction:"Leave your feedback below",input:["Feedback"],options:["Next"]},
    // 1001:{instruction:"Take the values for build version and proceed to report",question:"Whether 'Presence' column shows todays date?", options:["Yes","No"]},
    1000:{instruction:"Check the admin portal for health parameters for the nearby device or peer device",question:"Whether 'Last connected(LC)' of peer device is updated", options:["Yes","No"]},

    10001:{instruction:"Go to admin portal and reset the device. Leave your feedback below",conclusion:" The user has reseted the device.", input:["Feedback"], options:["Next"]},
    10000:{instruction:"Check for Network issue, Revisit the next day", options:["Next"]},
}

export default oneData;