import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import './App.css';
import Header from './components/ui/Header';
import Footer from './components/ui/Footer';
import Home from './components/pages/Home';
import Result from './components/pages/Result';
import Question from './components/pages/Questions';
import React, { useState, useEffect } from 'react';
import oneData from "./components/data/one";

function App() {

  const[name,setName] = useState("");
  const[userDetail, setUserDetail] = useState({});
  const[deviceInformation, setDeviceInformation] = useState({});
  const[deviceDetails,setDeviceDetails] = useState(null);


  const fetchQuestion = async (designation,department,Installid) => {
    let details = {
      Name:name.trim(),
      Designation:designation,
      Department:department
    }
    setUserDetail(details)

    //API call for different devices
    try{
      const devDetail = await axios.get(`https://tools.dev.afreespace.com/bless/debug.php?id=${Installid}&json=true`, 
      {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      console.log("devDetail",devDetail)
      let thirdSection = devDetail.data.third_section.split("\n\n")
      devDetail.data.third_section = JSON.parse(thirdSection[0])
      devDetail.data.first_section = devDetail.data.first_section.device
      setDeviceDetails(devDetail.data)
      
      deviceInfo(Installid,devDetail.data )
    }
    catch (err) {
      setDeviceDetails(err.response.data)
    }

  }
  



  
  
  const deviceInfo = (Installid,dD) => {
    let details = {
      InstallId:Installid,
      DeviceType:"category",
      DeviceSubType:"subCategory",
      MacId:dD.second_section.mac_address,
      blessId:dD.first_section.bless_id,
      SpaceId:dD.first_section.space_id,
      Account:dD.first_section.account_id,
      Location:dD.first_section.location_id,
      FloorId:dD.first_section.floor_id,
      LastConnect:dD.second_section.last_connect,
      LastUpdated:dD.third_section.lastUpdated,
      BuildVersion:dD.third_section.ack_build_deployed,
      Rssi:dD.third_section.wifi[2] ? dD.third_section.wifi[2] : `Last Updated Rssi ${dD.third_section.lastWifi[2]}`
    }
    setDeviceInformation(details)
  }

  
  return (
<Router>
      <div className="app">
        <Header/>    
        <Routes>
          <Route exact path="/" element={<Home name={name} setName={setName} fetchQuestion={fetchQuestion} deviceInfo={deviceInfo} />}/>
          <Route exact path="/question" element={<Question name={name} questions={oneData} userDetail={userDetail}  deviceInformation={deviceInformation} deviceDetail={deviceDetails} />}/>
          <Route exact path="/result" element={<Result/>}/>
        </Routes>
      <Footer/>    
      </div> 
</Router>
  );
}

export default App;
