import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useNavigate  } from "react-router-dom";
import "./Questions.css"
import moment from "moment";
import QuestionBlock from "../ui/QuestionBlock";

function Question({name,questions,userDetail, deviceInformation,deviceDetail}) {

    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })
    const [options,setOptions] = useState();
    const [currentQuestion,setcurrentQuestion] = useState();

    const detectSize = () => {
        detectHW({
          winWidth: window.innerWidth,
          winHeight: window.innerHeight,
        })
      }

    const navigate = useNavigate ();
    useEffect(() => {
        if(!name){
            navigate("/")
        }
        window.addEventListener('resize', detectSize)

    },[questions]);
     
    
    

    const currTime = moment()

    const quizInfoValue={
        fontWeight: "700",
        color:"#d73925"
    }
    return (
    deviceDetail === "not found device" ? <h1>Please provide the valid Install ID.</h1> : 
        
        <div className="quiz">
            <span className="subtitle">
                Welcome {name}
            </span>
            
            {questions && deviceDetail ?
                <>
                <div className="quizInfo">
                    <div className="deviceInfo1">
                        <span>Selected device: <span style={quizInfoValue}> {deviceInformation.DeviceType}</span></span>
                        <span>Local time: <span style={quizInfoValue}> {currTime.format("YYYY/MM/DD HH:mm")}</span></span>
                        <span>Account ID: <span style={quizInfoValue}> {deviceInformation.Account ? deviceInformation.Account : "-" }</span></span>
                        <span>Location ID: <span style={quizInfoValue}> {deviceInformation.Location ? deviceInformation.Location : "-"}</span></span>
                        <span>Floor Id: <span style={quizInfoValue}> {deviceInformation.FloorId ? deviceInformation.FloorId : "-"}</span></span>
                        <span>Install Id: <span style={quizInfoValue}> {deviceInformation.InstallId ? deviceInformation.InstallId : "-"}</span></span>
                    
                    {windowDimenion.winWidth < 614 ?<> 
                    <span>Mac Id: <span style={quizInfoValue}> {deviceInformation.MacId ? deviceInformation.MacId :"-"}</span></span>
                    <span>Bless ID: <span style={quizInfoValue}> {deviceInformation.blessId ? deviceInformation.blessId : "-"}</span></span>
                    <span>SpaceId: <span style={quizInfoValue}> {deviceInformation.SpaceId ? deviceInformation.SpaceId : "-"}</span></span>
                    <span>Rssi: <span style={quizInfoValue}> {deviceInformation.Rssi ? deviceInformation.Rssi : "-"}</span></span>
                    <span>Build version: <span style={quizInfoValue}> {deviceInformation.BuildVersion ? deviceInformation.BuildVersion :"-"}</span></span>
                    </> 
                    : null    
                    }
                    </div> 
                    
                    {windowDimenion.winWidth > 614 ?
                    <div className="deviceInfo2">
                    <span>Mac Id: <span style={quizInfoValue}> {deviceInformation.MacId ? deviceInformation.MacId :"-"}</span></span>
                    <span>Bless ID: <span style={quizInfoValue}> {deviceInformation.blessId ? deviceInformation.blessId : "-"}</span></span>
                    <span>SpaceId: <span style={quizInfoValue}> {deviceInformation.SpaceId ? deviceInformation.SpaceId : "-"}</span></span>
                    <span>Rssi: <span style={quizInfoValue}> {deviceInformation.Rssi ? deviceInformation.Rssi : "-"}</span></span>
                    <span>Build version: <span style={quizInfoValue}> {deviceInformation.BuildVersion ? deviceInformation.BuildVersion :"-"}</span></span>
                    </div>: null}
                    
                   
                </div>
                <QuestionBlock 
                    questions={questions}
                    userDetail={userDetail}
                    deviceInformation={deviceInformation}
                    currTime={currTime}
                    />

                </> :
                <CircularProgress
                    style={{margin:100}} 
                    color="primary"
                    thickness={1}
                    size={75}      
                />
            } 
        </div>
    );
}

export default Question;