import { Link } from "react-router-dom";
import "../../css/Header.css"

function Header() {
    return ( <div className="header">
        <Link to="/" className="title">
            Support tool <span style={{color:"#38d68b"}}>.</span>
        </Link>
        <hr className="divider"/>
    </div> );
}

export default Header;
